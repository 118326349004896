import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';

import { useEffect, useState } from 'react';
import { ManualAction } from '../../../../../../hooks/useManualActions';

export interface DeploymentStatus {
  health: string;
  sync: string;
}

export const useDeploymentStatus = (
  subscriptionId: string,
  manualActions?: ManualAction[],
) => {
  const configApi = useApi(configApiRef);
  const { fetch } = useApi(fetchApiRef);

  const [statuses, setStatuses] = useState<Record<
    string,
    DeploymentStatus
  > | null>(null);

  useEffect(() => {
    if (!subscriptionId) return;

    const nonResolvedManualActions = manualActions?.filter(
      action => action.status === 'INITIALIZED',
    );

    if (nonResolvedManualActions && nonResolvedManualActions.length > 0) {
      setStatuses({
        [subscriptionId]: {
          health: 'Stand by',
          sync: 'Stand by',
        },
      });
      return;
    }

    const backendUrl = configApi.getString('backend.baseUrl');
    fetch(`${backendUrl}/api/aap-argocd/subscription/${subscriptionId}/status`)
      .then(response => {
        if (!response.ok) {
          return {
            error: `Failed to fetch status from Argo (${response.status} ${response.statusText})`,
          };
        }

        return response.json();
      })
      .then(setStatuses);
  }, [configApi, fetch, subscriptionId, manualActions]);

  return statuses;
};
