import React from 'react';
import { Chip, Typography, makeStyles } from '@material-ui/core';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import { type Entity } from '@backstage/catalog-model';
import { RELATION_DEPENDENCY_OF } from '@backstage/catalog-model';
import { Skeleton } from '@material-ui/lab';
import { SUBSCRIPTION_PROJECT_ANNOTATION } from 'custom-annotations';

type ProductVersionsTableRowsChipProps = {
  entity: Entity;
};

const useProductVersionDrawerHeaderStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: 'flex-start',
  },
  chip: {
    margin: theme.spacing(0),
  },
}));

export const ProductVersionsTableRowsChip = (
  props: ProductVersionsTableRowsChipProps,
) => {
  const { entity } = props;
  const classes = useProductVersionDrawerHeaderStyles();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_DEPENDENCY_OF,
    kind: 'component',
  });

  if (loading) return <Skeleton variant="text" />;
  if (error) return null;

  const subcription = entities?.find((ent: Entity) => {
    return ent.metadata.annotations?.[SUBSCRIPTION_PROJECT_ANNOTATION];
  });

  if (!subcription) return <Typography color="textSecondary">-</Typography>;

  return (
    <div className={classes.root}>
      <Chip
        className={classes.chip}
        key={subcription?.metadata.uid}
        label={
          subcription?.metadata.annotations?.[
            SUBSCRIPTION_PROJECT_ANNOTATION
          ] ?? 'Unknown project'
        }
        color="primary"
        variant="outlined"
        size="small"
      />
    </div>
  );
};
