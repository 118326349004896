import { Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { EmptyState } from '@internal/backstage-plugin-adeo-core-components-react';
import { DeploymentAccordion } from './DeploymentAccordion/DeploymentAccordion';
import { ProductDeploymentFilters } from './ProductDeploymentFilters/ProductDeploymentFilters';
import { DeploymentDrawer } from './DeploymentDrawer/DeploymentDrawer';
import { useDeploymentsStore } from './store/deployments-store';
import {
  SubscriptionAndVersion,
  useProductSubscriptionsAndVersions,
} from '../../../../../hooks/useProductSubscriptionsAndVersions';
import {
  useGetProductManualActionsQuery,
  type ManualAction,
} from '../../../../../hooks/useManualActions';
import noDeploymentsLight from '../../../../../../../assets/empty-states/no-deployments-light.svg';
import noDeploymentsDark from '../../../../../../../assets/empty-states/no-deployments-dark.svg';
import noDeploymentsSearchLight from '../../../../../../../assets/empty-states/no-deployments-search-light.svg';
import noDeploymentsSearchDark from '../../../../../../../assets/empty-states/no-deployments-search-dark.svg';
import { useThemeMode } from '../../../../../../../utils/useThemeMode';

const useProductDeploymentsStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export const ProductDeployments = () => {
  const classes = useProductDeploymentsStyles();
  const theme = useThemeMode();

  const closeDrawer = useDeploymentsStore(state => state.closeDrawer);
  const setSearchQuery = useDeploymentsStore(state => state.setSearchQuery);
  const setSubscriptionsAndVersions = useDeploymentsStore(
    state => state.setSubscriptionsAndVersions,
  );
  const filteredSubscriptionsAndVersions = useDeploymentsStore(
    state => state.filteredSubscriptionsAndVersions,
  );
  const resetFilters = useDeploymentsStore(state => state.resetFilters);
  const { entity } = useEntity();

  const subscriptionsAndVersions = useProductSubscriptionsAndVersions(entity);

  useEffect(() => {
    setSubscriptionsAndVersions(
      subscriptionsAndVersions.filter(
        (item): item is SubscriptionAndVersion => !('error' in item),
      ),
    );
  }, [subscriptionsAndVersions, setSubscriptionsAndVersions]);

  const onSearch = (query: string) => {
    setSearchQuery(query);
  };

  // Reset filters on unmount
  useEffect(() => {
    return () => {
      resetFilters();
      closeDrawer();
    };
  }, [resetFilters, closeDrawer]);

  let content = null;
  const isProductVersion = entity?.spec?.type === 'productVersion';

  const manualActions = useGetProductManualActionsQuery({
    productId: entity?.metadata.name,
    enabled: entity.spec?.type === 'product',
  });

  // group manual actions by subscription
  const manualActionsGroupedBySubscription = useMemo(() => {
    if (!manualActions.data?.data) return {};

    return manualActions.data.data.reduce(
      (acc, action) => {
        if (!acc[action.subscriptionId]) {
          acc[action.subscriptionId] = [];
        }
        acc[action.subscriptionId].push(action);
        return acc;
      },
      {} as Record<string, ManualAction[]>,
    );
  }, [manualActions]);

  if (subscriptionsAndVersions.length === 0) {
    const illustration = (
      <img
        src={theme === 'light' ? noDeploymentsLight : noDeploymentsDark}
        alt={`No deployments found for this ${isProductVersion ? 'product version' : 'product'}`}
      />
    );
    content = (
      <EmptyState
        title={`No deployments found for this ${isProductVersion ? 'product version' : 'product'}`}
        description={`There is no deployment for this ${isProductVersion ? 'product version' : 'product'}, as soon as a deployment is available you will find it here.`}
        illustration={illustration}
      />
    );
  } else if (filteredSubscriptionsAndVersions.length === 0) {
    const illustration = (
      <img
        src={
          theme === 'light' ? noDeploymentsSearchLight : noDeploymentsSearchDark
        }
        alt="No deployments found"
      />
    );
    content = (
      <EmptyState
        title="No deployments match your search"
        description="We couldn't find any deployments matching your search for this product. Please try refining your search terms or explore our curated categories to discover relevant deployments."
        illustration={illustration}
      />
    );
  }

  if (
    subscriptionsAndVersions.length !== 0 &&
    filteredSubscriptionsAndVersions.length !== 0
  ) {
    content = filteredSubscriptionsAndVersions
      .map(({ subscription, productVersion }) => {
        if (subscription && productVersion) {
          const manualActionsForSubscription =
            manualActionsGroupedBySubscription[subscription?.metadata.name];
          return (
            <DeploymentAccordion
              subscription={subscription}
              productVersion={productVersion}
              manualActions={manualActionsForSubscription}
              key={subscription.metadata.uid}
            />
          );
        }
        return null;
      })
      .filter(Boolean);
  }

  return (
    <>
      <Container maxWidth="lg" className={classes.root}>
        <Grid lg={12} item>
          <ProductDeploymentFilters onSearch={onSearch} />
          {content}
        </Grid>
      </Container>
      <DeploymentDrawer />
    </>
  );
};
