import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  RELATION_DEPENDENCY_OF,
  stringifyEntityRef,
  Entity,
} from '@backstage/catalog-model';

import { useGetProductManualActionsQuery } from '../../../../../hooks/useManualActions';
import { ManualActionsPopup } from '../../../../../../common/ManualActionsPopup';
import { SUBSCRIPTION_PROJECT_ANNOTATION } from 'custom-annotations';

type ProductVersionsTableRowsTitleProps = {
  entity: Entity;
  product: Entity;
};

const useProductVersionDrawerHeaderStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    justifyContent: 'center',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 700,
  },
}));

export const ProductVersionsTableRowsTitle = (
  props: ProductVersionsTableRowsTitleProps,
) => {
  const { entity, product } = props;
  const classes = useProductVersionDrawerHeaderStyles();

  const manualActions = useGetProductManualActionsQuery({
    productId: entity?.metadata.name,
  });

  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_DEPENDENCY_OF,
    kind: 'component',
  });

  if (loading) return <Skeleton variant="text" />;
  if (error) return null;

  const subscription = entities?.find((ent: Entity) => {
    return ent.metadata.annotations?.[SUBSCRIPTION_PROJECT_ANNOTATION];
  });

  const nonResolvedManualActionsForSubscription =
    manualActions.data?.data?.filter(
      action =>
        action.subscriptionId === subscription?.metadata.name &&
        action.status === 'INITIALIZED',
    );

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.bold}>
        {entity.metadata.name}
      </Typography>
      {subscription &&
        nonResolvedManualActionsForSubscription &&
        nonResolvedManualActionsForSubscription.length > 0 && (
          <ManualActionsPopup
            rows={[
              {
                title: entity.metadata.name,
                project:
                  subscription?.metadata.annotations?.[
                    SUBSCRIPTION_PROJECT_ANNOTATION
                  ] ?? 'Unknown project',
                manualActions: nonResolvedManualActionsForSubscription?.length,
                route: `/catalog/${product.metadata.namespace}/component/${product.metadata.name}/deployments?manual-actions=${encodeURIComponent(stringifyEntityRef(subscription))}`,
              },
            ]}
          />
        )}
    </div>
  );
};
