import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Chip,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import React, { useState, useEffect, useMemo } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { DeploymentAccordionStatus } from './AccordionHeader/DeploymentAccordionStatus';
import { DeploymentAccordionTitle } from './AccordionHeader/DeploymentAccordionTitle';
import { DeploymentAccordionGitInfo } from './AccordionHeader/DeploymentAccordionGitInfo';
import { DeploymentAccordionOwner } from './AccordionHeader/DeploymentAccordionOwner';
import { DeploymentAccordionContent } from './AccordionContent/DeploymentAccordionContent';
import { DeploymentAccordionActions } from './AccordionHeader/DeploymentAccordionActions';
import {
  ENTITY_VERSION_COMMIT_ID_ANNOTATION,
  ENTITY_VERSION_COMMIT_MESSAGE_ANNOTATION,
  ENTITY_VERSION_REF_NAME_ANNOTATION,
  SUBSCRIPTION_PROJECT_ANNOTATION,
  SUBSCRIPTION_TYPE_ANNOTATION,
} from 'custom-annotations';
import { useDeploymentStatus } from './useDeploymentStatus';
import { useDeploymentsStore } from '../store/deployments-store';
import { SubscriptionEntity } from '../../../../../../../../types/SubscriptionEntity';

import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { InspectEntityDialog } from '@backstage/plugin-catalog-react';
import { useSearchParams } from 'react-router-dom';
import { ManualAction } from '../../../../../../hooks/useManualActions';
import partition from 'lodash.partition';
import { ManualActionsDialog } from './ManualActionsDialog/ManualActionsDialog';

const AccordionSummary = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: -1,
    minHeight: 56,

    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    width: '100%',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const useGetStatuses = (
  subscription: SubscriptionEntity,
  manualActions?: ManualAction[],
) => {
  const statuses = useDeploymentStatus(
    subscription.metadata.name,
    manualActions,
  );

  const productHealthStatus = statuses?.error
    ? undefined
    : statuses?.[subscription.metadata.name]?.health;

  const productSyncStatus = statuses?.error
    ? undefined
    : statuses?.[subscription.metadata.name]?.sync;

  return { productHealthStatus, productSyncStatus, statuses };
};

const useDeploymentAccordionStyles = makeStyles(theme => ({
  accordion: {
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  },
  redBorder: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  accordionSummaryContent: {
    width: `calc(100% - 24px - ${theme.spacing(1)}px)`, // Remove icon size + spacing
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  chipContainer: {
    width: '130px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  chip: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.main,
    margin: 0,
    maxWidth: '100%',
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
  accordionDetails: {
    paddingTop: 0,
  },
}));

export const DeploymentAccordion = ({
  subscription,
  productVersion,
  manualActions,
}: {
  subscription: SubscriptionEntity;
  productVersion: Entity;
  manualActions?: ManualAction[];
}) => {
  const classes = useDeploymentAccordionStyles();
  const [open, setOpen] = useState(false);
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);
  const [manualActionsDialogOpen, setManualActionsDialogOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('subscription') === stringifyEntityRef(subscription))
      setOpen(true);
    if (
      searchParams.get('manual-actions') === stringifyEntityRef(subscription)
    ) {
      setManualActionsDialogOpen(true);
      setOpen(true);
    }
  }, [searchParams, subscription]);

  const healthStatusFilter = useDeploymentsStore(
    state => state.healthStatusFilter,
  );
  const syncStatusFilter = useDeploymentsStore(state => state.syncStatusFilter);
  const setProductStatus = useDeploymentsStore(state => state.setProductStatus);

  const { productHealthStatus, productSyncStatus, statuses } = useGetStatuses(
    subscription,
    manualActions,
  );

  useEffect(() => {
    if (statuses) setProductStatus(statuses);
  }, [statuses, setProductStatus]);

  const onChange = (_: React.ChangeEvent<{}>, expanded: boolean) => {
    setOpen(expanded);
    setSearchParams({});
  };

  const handleManualActiuonsDialogClose = () => {
    setManualActionsDialogOpen(false);
    setSearchParams({});
  };

  const shouldBeDisplayed = useMemo(
    () =>
      (!productHealthStatus && !productSyncStatus) ||
      (healthStatusFilter.includes(productHealthStatus ?? '') &&
        syncStatusFilter.includes(productSyncStatus ?? '')),
    [
      healthStatusFilter,
      syncStatusFilter,
      productSyncStatus,
      productHealthStatus,
    ],
  );

  const handleInspectionDialogClose = () => {
    setInspectionDialogOpen(false);
  };

  const handleResolveManualActions = () => {
    setManualActionsDialogOpen(true);
    setSearchParams({ 'manual-actions': stringifyEntityRef(subscription) });
  };

  const [nonResolvedManualActions, resolvedManualActions] = useMemo(() => {
    return partition(manualActions, { status: 'INITIALIZED' });
  }, [manualActions]);

  return (
    shouldBeDisplayed && (
      <>
        <Accordion
          className={
            nonResolvedManualActions && nonResolvedManualActions.length > 0
              ? `${classes.accordion} ${classes.redBorder}`
              : classes.accordion
          }
          onChange={onChange}
          expanded={open}
        >
          <AccordionSummary>
            {open ? (
              <ExpandLessIcon
                className={classes.expandIcon}
                data-testid="icon-opened"
              />
            ) : (
              <ExpandMoreIcon
                className={classes.expandIcon}
                data-testid="icon-closed"
              />
            )}
            <div className={classes.accordionSummaryContent}>
              <DeploymentAccordionTitle
                title={
                  subscription.metadata.title ?? subscription.metadata.name
                }
                subtitle={
                  subscription.metadata.annotations?.[
                    SUBSCRIPTION_TYPE_ANNOTATION
                  ]
                }
                nonResolvedManualActions={nonResolvedManualActions}
              />
              <DeploymentAccordionStatus
                nonResolvedManualActions={nonResolvedManualActions}
                status={statuses?.[subscription.metadata.name]}
              />
              <div className={classes.chipContainer}>
                <Chip
                  label={
                    subscription.metadata.annotations?.[
                      SUBSCRIPTION_PROJECT_ANNOTATION
                    ]
                  }
                  size="small"
                  className={classes.chip}
                  variant="outlined"
                />
              </div>
              <DeploymentAccordionGitInfo
                branchOrTag={
                  productVersion?.metadata.annotations?.[
                    ENTITY_VERSION_REF_NAME_ANNOTATION
                  ]
                }
                commitId={
                  productVersion?.metadata.annotations?.[
                    ENTITY_VERSION_COMMIT_ID_ANNOTATION
                  ]
                }
                commitMessage={
                  productVersion?.metadata.annotations?.[
                    ENTITY_VERSION_COMMIT_MESSAGE_ANNOTATION
                  ]
                }
              />
              <DeploymentAccordionOwner subscription={subscription} />
              <DeploymentAccordionActions
                subscription={subscription}
                nonResolvedManualActions={nonResolvedManualActions}
                resolvedManualActions={resolvedManualActions}
                onInspectEntity={() => setInspectionDialogOpen(true)}
                onResolveManualActions={handleResolveManualActions}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <DeploymentAccordionContent
              subscription={subscription}
              productVersion={productVersion}
              statuses={statuses ?? undefined}
              nonResolvedManualActions={nonResolvedManualActions}
              onResolveManualActions={handleResolveManualActions}
            />
          </AccordionDetails>
        </Accordion>
        {subscription && (
          <InspectEntityDialog
            open={inspectionDialogOpen}
            entity={subscription}
            onClose={handleInspectionDialogClose}
          />
        )}
        {manualActions && (
          <ManualActionsDialog
            open={manualActionsDialogOpen}
            onClose={handleManualActiuonsDialogClose}
            subscription={subscription}
            manualActions={manualActions}
          />
        )}
      </>
    )
  );
};
