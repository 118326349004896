import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { StatusBadge } from '../../StatusBadge';
import { Alert } from '@material-ui/lab';
import { ManualAction } from '../../../../../../../hooks/useManualActions';

const useDeploymentAccordionStatusStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '150px',
    flexDirection: 'column',
  },
  badge: {
    marginTop: theme.spacing(0.5),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 'initial',
  },
  manualActionsMessage: {
    color: theme.palette.errorText,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 'initial',
  },
  bold: {
    fontWeight: 700,
  },
  statusContainer: {
    display: 'flex',
  },
  noStatusAlert: {
    width: '100%',
    '& [class*="MuiAlert-icon"], & [class*="MuiAlert-message"]': {
      padding: 0,
    },
  },
}));

interface DeploymentAccordionStatusProps {
  status?: {
    sync: string;
    health: string;
  };
  nonResolvedManualActions?: ManualAction[];
  withoutErrorMessages?: boolean;
}

export const DeploymentAccordionStatus = ({
  status,
  nonResolvedManualActions,
  withoutErrorMessages = false,
}: DeploymentAccordionStatusProps) => {
  const classes = useDeploymentAccordionStatusStyles();

  if (nonResolvedManualActions && nonResolvedManualActions.length > 0) {
    return (
      <div className={classes.root}>
        <div className={classes.statusContainer}>
          <StatusBadge
            className={classes.badge}
            status="ManualActionRequired"
          />
          <Typography variant="body2">Stand by</Typography>
        </div>
        {!withoutErrorMessages && (
          <Typography variant="body2" className={classes.manualActionsMessage}>
            <span className={classes.bold}>
              {nonResolvedManualActions.length === 1
                ? '1 manual action'
                : `${nonResolvedManualActions.length} manual actions`}
            </span>
            <span> required</span>
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {status ? (
        <>
          <div className={classes.statusContainer}>
            <StatusBadge className={classes.badge} status={status?.health} />
            <Typography variant="body2">
              {status?.health ?? 'Unknown'}
            </Typography>
          </div>
          <div className={classes.statusContainer}>
            <StatusBadge className={classes.badge} status={status?.sync} />
            <Typography variant="body2">{status?.sync ?? 'Unknown'}</Typography>
          </div>
        </>
      ) : (
        <Alert severity="warning" className={classes.noStatusAlert}>
          No status
        </Alert>
      )}
    </div>
  );
};
